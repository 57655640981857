import {LitElement, html, css} from 'lit';
import {hover, animate} from "motion";
import { category } from './image-scroller-element';

const gradients = [
    `background: #8360c3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2ebf91, #8360c3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2ebf91, #8360c3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    `,
    `background: #108dc7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ef8e38, #108dc7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ef8e38, #108dc7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`,
  `background: #f12711; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f12711, #f5af19); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f12711, #f5af19); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */`,
  `background: #ff7e5f;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #feb47b, #ff7e5f);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #feb47b, #ff7e5f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`
]

export class BioElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        /* padding: 16px;
        max-width: 800px; */
        position: relative;
      }

      .bio {
        color: rgba(255, 255, 255, 0.50);
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .box {
        color: white;
        cursor: pointer; 
        text-decoration: none;
        display: inline-block;
        padding: 0 4px;

        /* border: 1px solid green; */
      }
    `;
  }

  static get properties() {
    return { 
      emailOn: {type: Boolean}
    };
  }


  constructor() {
    super();
    this.emailOn = false;
  }

  firstUpdated() {
    // https://motion.dev/docs/hover#hover-start
    this.shadowRoot.querySelectorAll(".box").forEach(box => {
        hover(box, (element) => {
            animate(element, { scale: 1.3 })
            return () => animate(element, { scale: 1 })
        }, {});
    });
  }

  _setCategory(newCat) {
    category.set(newCat);
  }

  _openLink(link) {
    window.open(link)
  }

  _toggleEmail() {
    this.emailOn = !this.emailOn;
  }

  render() {
    return html`
        <div class="bio">
            My interests go from graphics + ai, to <span class="box" style="${gradients[2]}" @click=${() => this._setCategory("f1")}>f1</span>,
             <span class="box" style="${gradients[2]}" @click=${() => this._setCategory("shows")}>tv shows</span>, 
             and my golden doodle <span class="box" style="${gradients[2]}" @click=${() => this._setCategory("chewy")}>Chewy</span>.
            I enjoy thinking about creative tooling, where AI sits in that, and more.
            Find me on <span class="box" style="${gradients[0]}" @click=${() => this._openLink("https://linkedin.com/in/dkraj")}>Linkedin</span>, 
            <span class="box" style="${gradients[0]}" @click=${this._toggleEmail}>${this.emailOn ? "david@davidkrajewski.com" : "Email"}</span>, or 
            <span class="box" style="${gradients[0]}" @click=${() => this._openLink("https://x.com/dkrajews123")}>X</span>.
        </div>
    `;
  }
}

window.customElements.define('bio-element', BioElement);
